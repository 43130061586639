<template>
  <div>
    <div class="space-y-2 py-4 pl-3">
      <el-timeline>
        <el-timeline-item
          v-for="(activity, index) in activities"
          :key="index"
          :icon="activity.icon"
          :color="activity.color"
          size="large"
          placement="top"
          class="my-timeline-item"
        >
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <h1 class="text-md font-bold inline-flex items-center">{{activity.title}}</h1>
            </div>
            <div class="text item">
              <el-descriptions direction="horizontal" :column="1" border>
                <el-descriptions-item label="Usuario">{{activity.account.fullname}}</el-descriptions-item><br>
                <el-descriptions-item label="Información">{{activity.content}}</el-descriptions-item>
                <el-descriptions-item label="Fecha">{{getFormattedDate(activity.timestamp)}}</el-descriptions-item>
              </el-descriptions>
            </div>
          </el-card>
        </el-timeline-item>
      </el-timeline>
    </div>
  </div>
</template>
<style>
.my-timeline-item .el-timeline-item__icon {
  font-size: 25px !important;
}
.my-timeline-item .el-timeline-item__node {
  width: 30px !important;
  height: 30px !important;
  left: -10px !important;
}
.my-timeline-item .el-card__header {
  padding: 10px 10px;
}
.text {
  font-size: 14px;
}
.item {
  margin-bottom: 18px;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both
}
.box-card {
  width: 100%;
}
</style>
<script>
import responsiveSize from '@/mixins/responsiveSize';
import error from '@/mixins/error';
import OrderService from "@/services/OrderService";
import dates from "@/utils/dates";
import 'moment/locale/es'
export default {
  name: 'History',
  components: {
  },
  mixins: [
    responsiveSize,
    error,
  ],
  data() {
    return {
      activities: []
    }
  },
  mounted() {
    if (this.$route.params.id) {
      this.getHistory(this.$route.params.id);
    }
  },
  methods: {
    async getHistory(orderId) {
      this.activities = await OrderService.getOrderHistory(orderId);
    },
    getFormattedDate(date) {
      let dateString = dates.toFormattedString(date, "dddd, MMMM D YYYY, h:mm:ss A")
      return dateString.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
    }
  },
}
</script>
